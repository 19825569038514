<template>
  <b-card class="card-custom" style="height: 500px;">
    <template #header>
      <div style="position: absolute; right: 10px; top: 8px;">
        <b-dropdown size="sm" variant="light" id="dropdown-1" :text="currentGroupTitle" right>
          <b-dropdown-item :active="currentGroup === 'hourly'" @click="changeDatasetGroup('hourly')">Hourly</b-dropdown-item>
          <b-dropdown-item v-if="['today','yesterday','all-time'].indexOf(currentDataset) === -1" :active="currentGroup === 'daily'" @click="changeDatasetGroup('daily')">Daily</b-dropdown-item>
          <b-dropdown-item v-if="['today','yesterday','this-week','last-week','this-month','last-month','all-time'].indexOf(currentDataset) === -1" :active="currentGroup === 'monthly'" @click="changeDatasetGroup('monthly')">Monthly</b-dropdown-item>
          <b-dropdown-item v-if="['today','yesterday','this-week','last-week','this-month','last-month','this-year','last-year'].indexOf(currentDataset) === -1" :active="currentGroup === 'yearly'" @click="changeDatasetGroup('yearly')">Yearly</b-dropdown-item>
        </b-dropdown>

        <b-dropdown size="sm" variant="light" id="dropdown-2" :text="currentDatasetTitle" right>
          <b-dropdown-item :active="currentDataset === 'today'" @click="changeDataset('today')">Today</b-dropdown-item>
          <b-dropdown-item :active="currentDataset === 'yesterday'" @click="changeDataset('yesterday')">Yesterday</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item :active="currentDataset === 'this-week'" @click="changeDataset('this-week')">This Week</b-dropdown-item>
          <b-dropdown-item :active="currentDataset === 'last-week'" @click="changeDataset('last-week')">Last Week</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item :active="currentDataset === 'this-month'" @click="changeDataset('this-month')">This month</b-dropdown-item>
          <b-dropdown-item :active="currentDataset === 'last-month'" @click="changeDataset('last-month')">Last month</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item :active="currentDataset === 'this-year'" @click="changeDataset('this-year')">This Year</b-dropdown-item>
          <b-dropdown-item :active="currentDataset === 'last-year'" @click="changeDataset('last-year')">Last Year</b-dropdown-item>
          <b-dropdown-item v-if="['daily','monthly'].indexOf(currentGroup) === -1" :active="currentDataset === 'all-time'" @click="changeDataset('all-time')">All Time</b-dropdown-item>
        </b-dropdown>
      </div>
      <h6 class="mb-0">{{title}}</h6>
    </template>
    <canvas id="line-chart"></canvas>
  </b-card>
</template>

<script>
import moment from 'moment';
import Chart from 'chart.js/auto';

export default {
  data() {
    return {
      chart: null,
      title: null,
      currentGroup: "hourly",
      currentGroupTitle: "Hourly",
      currentGroups: [
        {id: "hourly", text: "Hourly"},
        {id: "daily", text: "Daily"},
        {id: "monthly", text: "Monthly"},
        {id: "yearly", text: "Yearly"},
      ],
      currentDataset: "today",
      currentDatasetTitle: "Today",
      currentDatasetTitles: [
        {id: "today", text: "Today"},
        {id: "yesterday", text: "Yesterday"},
        {id: "this-week", text: "This Week"},
        {id: "last-week", text: "Last Week"},
        {id: "this-month", text: "This Month"},
        {id: "last-month", text: "Last Month"},
        {id: "this-year", text: "This Year"},
        {id: "last-year", text: "Last Year"},
        {id: "all-time", text: "All Time"},
      ],
      config: {
        type: "line",
        data: null,
        options: {
          maintainAspectRatio: false,
          responsive: true,
          lineTension: 0,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  padding: 25
                }
              }
            ]
          }
        }
      }
    }
  },

  methods: {
    changeDatasetGroup(id){
      let item = this.currentGroups.find(o => o.id === id);
      this.currentGroupTitle = item.text;
      this.currentGroup = item.id;
      this.changeDataset(this.currentDataset, item.id);
    },
    changeDataset(id){
      let item = this.currentDatasetTitles.find(o => o.id === id);
      this.currentDatasetTitle = item.text;
      this.currentDataset = item.id;

      if(id === "today"){
        let today = moment();
        this.fetchChartData(today, today);
      }
      else if(id === "yesterday"){
        let yesterday = moment().subtract(1,'day');
        this.fetchChartData(yesterday,yesterday);
      }
      else if(id === "this-week"){
        this.fetchChartData(moment().startOf('isoWeek'),moment().endOf('isoWeek'));
      }
      else if(id === "last-week"){
        this.fetchChartData(moment().subtract(1, 'weeks').startOf('isoWeek'),moment().subtract(1, 'weeks').endOf('isoWeek'));
      }
      else if(id === "this-month"){
        this.fetchChartData(moment().startOf('month'),moment());
      }
      else if(id === "last-month"){
        this.fetchChartData(moment().subtract(1, 'month').startOf('month'),moment().subtract(1, 'month').endOf('month'));
      }
      else if(id === "this-year"){
        this.fetchChartData(moment().startOf('year'),moment());
      }
      else if(id === "last-year"){
        this.fetchChartData(moment().subtract(1, 'year').startOf('year'),moment().subtract(1, 'year').endOf('year'));
      }
      else if(id === "all-time"){
        this.fetchChartData();
      }
      else{
        //nothing
      }
    },

    setTitle(datestart,dateend){
      if(!datestart && !dateend){
        this.title = 'All time';
        return;
      }

      let title = datestart.format('DD.MM.YYYY');
      if(dateend !== datestart){
        title = title+' / '+dateend.format('DD.MM.YYYY');
      }

      this.title = title;
    },

    parse_hourly(data){
      let labels = [];
      let missed = [];
      let picked = [];

      for(let i=0; i<=23; i++){
        let val = i < 10 ? `0${i}` : i;
        labels.push(val+':00');

        let h = data.find(o => o.h === i);
        if(h){
          missed.push(h.missed);
          picked.push(h.hungup);
        }
        else{
          missed.push(0);
          picked.push(0);
        }
      }

      return {labels,missed,picked};
    },

    parse_daily(data, {datestart,dateend}){
      let labels = [];
      let missed = [];
      let picked = [];
      let step = 0;

      while(datestart.add(step, 'days').diff(dateend) < 0) {
        let day = datestart.clone();
        labels.push(day.format('DD MMM'));

        let d = data.find(o => o.ymd === day.format('YYYY-MM-DD'));
        if(d){
          missed.push(d.missed);
          picked.push(d.hungup);
        }
        else{
          missed.push(0);
          picked.push(0);
        }
        step = 1;
      }

      return {labels,missed,picked};
    },

    parse_monthly(data, {datestart,dateend}){
      let labels = [];
      let missed = [];
      let picked = [];
      let step = 0;

      while(datestart.add(step, 'month').diff(dateend) < 0) {
        let month = datestart.clone();
        labels.push(month.format('MMM'));

        let d = data.find(o => o.ym === month.format('YYYY-M'));
        if(d){
          missed.push(d.missed);
          picked.push(d.hungup);
        }
        else{
          missed.push(0);
          picked.push(0);
        }
        step = 1;
      }

      return {labels,missed,picked};
    },

    parse_yearly(data){
      let labels = [];
      let missed = [];
      let picked = [];

      let firstY = moment().format('YYYY');
      let lastY = firstY;

      if(data.length > 0){
        firstY = data[0].y;
        lastY = data[data.length - 1].y;
      }

      for(let i=firstY; i<=lastY; i++){
        labels.push(i);
        let d = data.find(o => o.y === i);
        if(d){
          missed.push(d.missed);
          picked.push(d.hungup);
        }
        else{
          missed.push(0);
          picked.push(0);
        }
      }


      return {labels,missed,picked};
    },

    setChartData(data, dates){
      let {labels,missed,picked} = this.[`parse_${this.currentGroup}`](data,dates);

      return {
        labels: labels,
        datasets: [
          {
            label: "Missed calls",
            data: missed,
            backgroundColor: "rgba(255,0,0,.5)",
            borderColor: "#FF0000",
            borderWidth: 3
          },
          {
            label: "Successful calls",
            data: picked,
            backgroundColor: "rgba(0,128,0,.5)",
            borderColor: "#008000",
            borderWidth: 3
          }
        ]
      }
    },

    fetchChartData(datestart,dateend){
      this.setTitle(datestart,dateend);
      if(this.chart){
        this.chart.destroy();
      }

      this.$api.get('analytics/calls',{
        params: {
          datestart: this.currentDataset === 'all-time' ? null : datestart.format('YYYY-MM-DD'),
          dateend: this.currentDataset === 'all-time' ? null : dateend.format('YYYY-MM-DD'),
          groupBy: this.currentGroup
        }
      }).then(res => {
        this.config.data = this.setChartData(res.data,{datestart,dateend});
        this.chart = new Chart(document.getElementById('line-chart'), this.config);
      })
    },
  },

  mounted() {
    this.changeDataset('today');
  }
}
</script>
