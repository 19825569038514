<template>
  <b-card class="card-custom" style="height: 500px;">
    <template #header>
      <b-dropdown size="sm" variant="light" id="dropdown-today-chart" :text="currentDatasetTitle" style="position: absolute; right: 10px; top: 8px;" right>
        <b-dropdown-item :active="currentDataset === 'today'" @click="changeDataset('today')">Today</b-dropdown-item>
        <b-dropdown-item :active="currentDataset === 'yesterday'" @click="changeDataset('yesterday')">Yesterday</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item :active="currentDataset === 'this-week'" @click="changeDataset('this-week')">This Week</b-dropdown-item>
        <b-dropdown-item :active="currentDataset === 'last-week'" @click="changeDataset('last-week')">Last Week</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item :active="currentDataset === 'this-month'" @click="changeDataset('this-month')">This month</b-dropdown-item>
        <b-dropdown-item :active="currentDataset === 'last-month'" @click="changeDataset('last-month')">Last month</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item :active="currentDataset === 'this-year'" @click="changeDataset('this-year')">This Year</b-dropdown-item>
        <b-dropdown-item :active="currentDataset === 'last-year'" @click="changeDataset('last-year')">Last Year</b-dropdown-item>
      </b-dropdown>
      <h6 class="mb-0">{{title}}</h6>
    </template>
    <canvas id="pie-chart"></canvas>
  </b-card>
</template>

<script>
import moment from 'moment';
import Chart from 'chart.js/auto';

export default {
  data() {
    return {
      chart: null,
      title: null,
      currentDataset: "today",
      currentDatasetTitle: "Today",
      currentDatasetTitles: [
        {id: "today", text: "Today"},
        {id: "yesterday", text: "Yesterday"},
        {id: "this-week", text: "This Week"},
        {id: "last-week", text: "Last Week"},
        {id: "this-month", text: "This Month"},
        {id: "last-month", text: "Last Month"},
        {id: "this-year", text: "This Year"},
        {id: "last-year", text: "Last Year"},
      ],
      config: {
        type: "pie",
        data: {
          labels: [],
          datasets: []
        },
        options: {
          maintainAspectRatio: false,
          responsive: true
        }
      }
    }
  },

  methods: {
    empty(){
      this.config.data.labels = [
        `No data available`
      ]
      this.config.data.datasets = [
        {
          data: [1],
          backgroundColor: [
            'rgba(0,0,0,.05)',
          ],
          hoverOffset: 4
        }
      ]
    },
    populate({missed,picked}){
      this.config.data.labels = [
        `Missed (${missed.cnt})`,`Picked Up (${picked.cnt})`
      ]
      this.config.data.datasets = [
        {
          data: [missed.cnt,picked.cnt],
          backgroundColor: [
            'rgba(255,0,0,.9)',
            'rgba(0,128,0,.9)',
          ],
          hoverOffset: 4
        }
      ]
    },

    changeDataset(id){
      let item = this.currentDatasetTitles.find(o => o.id === id);
      this.currentDatasetTitle = item.text;
      this.currentDataset = item.id;

      if(id === "today"){
        let today = moment();
        this.fetchChartData(today, today);
      }
      else if(id === "yesterday"){
        let yesterday = moment().subtract(1,'day');
        this.fetchChartData(yesterday,yesterday);
      }
      else if(id === "this-week"){
        this.fetchChartData(moment().startOf('isoWeek'),moment().endOf('isoWeek'));
      }
      else if(id === "last-week"){
        this.fetchChartData(moment().subtract(1, 'weeks').startOf('isoWeek'),moment().subtract(1, 'weeks').endOf('isoWeek'));
      }
      else if(id === "this-month"){
        this.fetchChartData(moment().startOf('month'),moment());
      }
      else if(id === "last-month"){
        this.fetchChartData(moment().subtract(1, 'month').startOf('month'),moment().subtract(1, 'month').endOf('month'));
      }
      else if(id === "this-year"){
        this.fetchChartData(moment().startOf('year'),moment());
      }
      else if(id === "last-year"){
        this.fetchChartData(moment().subtract(1, 'year').startOf('year'),moment().subtract(1, 'year').endOf('year'));
      }
      else{
        //nothing
      }
    },

    setTitle(datestart,dateend){
      let title = datestart.format('DD.MM.YYYY');
      if(dateend !== datestart){
        title = title+' / '+dateend.format('DD.MM.YYYY');
      }

      this.title = title;
    },

    fetchChartData(datestart,dateend){
      this.setTitle(datestart,dateend);
      if(this.chart){
        this.chart.destroy();
      }

      this.$api.get('analytics/calls',{
        params: {
          datestart: datestart.format('YYYY-MM-DD'),
          dateend: dateend.format('YYYY-MM-DD'),
          groupBy: "status"
        }
      }).then(res => {
        let missed = res.data.find(o => o.status === "missed");
        let picked = res.data.find(o => o.status === "hungup");

        if(res.data.length === 0){
          this.empty();
        }
        else{
          if(!missed){
            missed = {cnt: 0, status: "missed"};
          }

          if(!picked){
            picked = {cnt: 0, status: "hungup"};
          }

          if(missed.cnt === 0 && picked.cnt === 0){
            this.empty();
          }
          else{
            this.populate({missed,picked});
          }
        }
        this.chart = new Chart(document.getElementById('pie-chart'), this.config);
      })
    }
  },

  mounted() {
    this.changeDataset('today');
  }
}
</script>
