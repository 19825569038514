<template>
    <div id="waiting-room">
      <b-row>
          <b-col class="mb-3">
            <b-card no-body class="card-custom">
              <template #header>
                <div class="float-right text-muted">{{waitingRoom.length}} people</div>
                <h6 class="mb-0">Waiting Room</h6>
              </template>
              <b-list-group v-if="user && channelUsers && channels && waitingRoom && waitingRoom.length > 0" flush  class="clients-waiting-room">
                <template v-if="appointments.length > 0">
                  <b-list-group-item disabled class="text-uppercase small bg-light">
                    {{appointments.length}} Appointments
                  </b-list-group-item>
                  <b-list-group-item v-for="(item, k) in appointments" :key="k+'m'">
                    <b-row>
                      <b-col cols="1">
                        <b-button class="rounded-circle btn-rounded-35" variant="outline-secondary">{{k+1}}</b-button>
                      </b-col>
                      <b-col>
                        <strong v-if="item.name">{{item.name}}</strong>
                        <strong v-else class="text-muted">Anonymous</strong>
                        <div class="small">
                          on <span v-if="item.channel">{{item.channel.name}}</span>
                          <span> since: {{item.since}}</span>
                        </div>
                        <div class="small" v-if="item.appointment.user">
                          <strong v-if="item.appointment.user.isMe">with you</strong>
                          <span v-else>with agent: <strong>{{item.appointment.user.fullName}}</strong></span>
                        </div>
                      </b-col>
                      <b-col class="text-right">
                        <!--
                        <b-button :variant="item.appointment ? 'primary' : 'outline-primary'" size="sm" v-b-tooltip.hover :title="item.appointment ? 'Meeting info' : 'More info'" class="mr-1">
                          <BIconCalendar2Event v-if="item.appointment"/>
                          <BIconInfoCircle v-else/>
                        </b-button>
                      -->

                        <b-button-group v-if="item.appointment && item.appointment.user && item.appointment.user.isMe">
                          <b-button @click="joinMeeting(item)" variant="outline-primary" size="sm" v-b-tooltip.hover title="Join Meeting"><BIconTelephoneFill/> Join</b-button>
                          <!--<b-button @click="joinMeeting(item)" variant="outline-primary" size="sm" v-b-tooltip.hover title="Join with Audio only"><BIconMic/></b-button>-->
                        </b-button-group>

                        <!--<b-button variant="outline-danger" size="sm" v-b-tooltip.hover title="Reject" class="ml-1"><BIconXCircle/></b-button>-->
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                </template>
                <template v-if="adhoc.length > 0">
                  <b-list-group-item disabled class="text-uppercase small bg-light">
                    {{adhoc.length}} Ad-hoc calls
                  </b-list-group-item>
                  <b-list-group-item v-for="(item, k) in adhoc" :key="k+'n'">
                    <b-row>
                      <b-col cols="1">
                        <b-button class="rounded-circle btn-rounded-35" variant="outline-secondary">{{k+1}}</b-button>
                      </b-col>
                      <b-col>
                        <strong v-if="item.name">{{item.name}}</strong>
                        <strong v-else class="text-muted">Anonymous</strong>
                        <div class="small">
                          on <span v-if="item.channel">{{item.channel.name}}</span>
                          <span> since: {{item.since}}</span>
                        </div>
                      </b-col>
                      <b-col class="text-right">
                        <!--
                        <b-button :variant="item.appointment ? 'primary' : 'outline-primary'" size="sm" v-b-tooltip.hover :title="item.appointment ? 'Meeting info' : 'More info'" class="mr-1">
                          <BIconCalendar2Event v-if="item.appointment"/>
                          <BIconInfoCircle v-else/>
                        </b-button>
                      -->

                        <b-button-group>
                          <b-button @click="joinMeeting(item)" variant="outline-primary" size="sm" v-b-tooltip.hover title="Answer"><BIconTelephoneFill/> Take Call</b-button>
                          <!--<b-button @click="joinMeeting(item)" variant="outline-primary" size="sm" v-b-tooltip.hover title="Answer with Audio only"><BIconMic/></b-button>-->
                        </b-button-group>

                        <!--<b-button variant="outline-danger" size="sm" v-b-tooltip.hover title="Reject" class="ml-1"><BIconXCircle/></b-button>-->
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                </template>
              </b-list-group>
              <div v-else class="p-3 font-italic clients-waiting-room-empty">the waiting room is empty</div>
            </b-card>
          </b-col>
      </b-row>
    </div>
</template>

<script>
import {BIconTelephoneFill} from 'bootstrap-vue';
import moment from 'moment';
export default {
    props: ['user'],
    components: {
      BIconTelephoneFill
    },
    methods: {
      getChannel(id){
        return this.channels.find(o => o.id === id);
      },
      getUser(id){
        let agent = this.channelUsers.find(o => o.id === id);
        if(!agent){
          return null;
        }
        let isMe = this.user.get('id') === agent.id;
        return Object.assign(agent,{
          isMe: isMe,
          cnt: isMe ? 1 : 0
        });
      },
      joinMeeting(client){
        console.log(client);
        this.$callstr.signal.emit('call-picked-wait',{to: client.socketId});

        this.$store.dispatch('Callcenter/addActiveCall',{
          client: client,
          type: "answer"
        });
      },
    },
    computed: {
      channels(){
        return this.$store.getters['Callcenter/getChannels'];
      },

      waitingRoom(){
        return this.$store.getters['Callcenter/getWaitingRoom'].map(item => {
          item.channel = this.getChannel(item.channelId);
          item.since = moment().format('DD/MM/YYYY HH:mm');
          if(item.appointment){
            item.appointment.user = this.getUser(item.appointment.userId);
          }
          return item;
        });
      },

      adhoc(){
        return this.waitingRoom.filter(o => !o.appointment)
      },
      appointments(){
        return this.waitingRoom.filter(o => o.appointment).sort(function(a,b){
          if ( a.appointment.user && a.appointment.user.cnt < b.appointment.user.cnt ){
            return 1;
          }
          if ( a.appointment.user && a.appointment.user.cnt > b.appointment.user.cnt ){
            return -1;
          }
          return 0;
        });
      },

      channelUsers(){
        return this.$store.getters['Callcenter/getUsers'];
      }
    },
};
</script>
