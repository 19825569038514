<template>
    <div id="dashboard" class="page">
      <ClientsOnline v-if="user" />

      <b-row>
        <b-col lg="6" sm="12" md="12">
          <WaitingRoom v-if="user" :user="user" />
        </b-col>
        <b-col lg="6" sm="12" md="12">
          <ChannelsRTVisitors v-if="user"/>
        </b-col>
      </b-row>
      <Todo v-if="user && user.hasPermission('crm:calendar.event.search')"/>
      <!--<CallsCardTemplate v-if="user && user.hasPermission('rtc:calls.search')" :loggedUser="user" title="Recent Calls" handler="allCalls" showPagination="false"/>-->
      <LineChart v-if="user && user.hasPermission('rtc:analytics.calls')"/>
      <b-row class="mt-3" v-if="user && user.hasPermission('rtc:analytics.calls') && user.hasPermission('rtc:analytics.calls.byUsers')">
        <b-col lg="6" sm="12" md="12">
          <PolarArea/>
        </b-col>
        <b-col lg="6" sm="12" md="12">
          <PieChart/>
        </b-col>
      </b-row>
    </div>
</template>

<script>

import ClientsOnline from '@/modules/callcenter/components/Clients.Online';
import ChannelsRTVisitors from '@/modules/callcenter/components/Channels.RT.Visitors';
import WaitingRoom from '@/modules/callcenter/components/Clients.Waiting.Room';
//import CallsCardTemplate from '@/modules/callcenter/components/Calls/Calls.Card.Template';

import LineChart from '@/common/components/Charts/Line';
import PieChart from '@/common/components/Charts/Pie';
import PolarArea from '@/common/components/Charts/PolarArea';
import Todo from '@/modules/crm/components/Todo';

export default {
    props: ['user'],
    components: {
      ClientsOnline,
      WaitingRoom,
      ChannelsRTVisitors,
      LineChart,
      PieChart,
      PolarArea,
      Todo
      //CallsCardTemplate
    },
    data(){
        return {
            page: {
                title: "Dashboard"
            },
            breadcrumbs: [
                {
                    title: "Dashboard"
                }
            ],
            chartdata: null,
            options: null
        };
    },
    mounted(){
        this.$emit('set-sidebar',{active: "dashboard"});
        this.$emit('set-navbar',{active: "dashboard"});
    }
};
</script>
