<template>
    <div id="channels-rt-visitors">
      <b-row>
          <b-col class="mb-3">
            <b-card no-body class="card-custom">
              <template #header>
                <div class="float-right text-muted">{{total}} people</div>
                <h6 class="mb-0">Realtime Visitors</h6>
              </template>
              <b-list-group v-if="channels && channels.length > 0" flush  class="clients-online-visitors">
                  <b-list-group-item :to="{name: 'channel.board', params: {channelId: item.id}}" v-for="(item, k) in channels" :key="k">
                    <div class="float-right" style="font-size: 25px; font-weight: 700;">{{ item.clients.online }}</div>
                    <div class="small font-italic">channel:</div>
                    {{ getChannelName(item.id) }}
                  </b-list-group-item>
              </b-list-group>
              <div v-else class="p-3 font-italic text-secondary">no channels</div>
            </b-card>
          </b-col>
      </b-row>
    </div>
</template>

<script>
export default {
    props: [],
    components: {

    },
    data(){
      return {
        total: 0
      }
    },
    methods: {
      getChannelName(id){
        let channel = this.channels.find(o => o.id === id);
        if(!channel){
          return id;
        }
        return channel.name;
      }
    },
    computed: {
      channels(){
        return this.$store.getters['Callcenter/getChannels'];
      },
    },

    watch: {
      "channels": function(){
        let total = 0;
        for(let i in this.channels){
          total += this.channels[i].clients.online;
        }
        this.total = total;
      }
    }
};
</script>
