<template>
  <b-card no-body class="card-custom mb-3">
    <template #header>
      <b-dropdown size="sm" variant="light" id="dropdown-today-chart" :text="currentDatasetTitle" style="position: absolute; right: 10px; top: 8px;" right>
        <b-dropdown-item
          v-for="(item,key) in currentDatasetTitles" :key="key"
          :active="currentDataset === item.id"
          @click="changeDataset(item.id)">
          {{item.text}}
        </b-dropdown-item>
      </b-dropdown>
      <h6 class="mb-0">Events: {{title}}</h6>
    </template>
    <b-list-group v-if="!loading" flush style="max-height: 300px; overflow-y: auto;">
      <template v-if="events.length > 0">
        <b-list-group-item v-for="(ev,key) in events" :key="key">
          <b-row>
            <b-col lg="3" md="12" sm="12">
              <div class="float-left mr-3">
                <BIconCalendar2Event v-if="ev.pot === 'future'" font-scale="2.5"/>
                <BIconCalendar2Check v-if="ev.pot === 'past'" font-scale="2.5" class="text-muted"/>
              </div>
              <div>{{ev.starttimeFormatted}} - {{ev.endtimeFormatted}}</div>
              <div class="small">{{ev.date}}</div>
            </b-col>
            <b-col lg="7" md="12" sm="12">
              <div>{{ev.title ? (ev.title.length > 80 ? ev.title.substr(0,80)+'[...]' : ev.title) : '-'}}</div>
              <small class="text-muted">{{ev.content ? (ev.content.length > 80 ? ev.content.substr(0,80)+'[...]' : ev.content) : '-'}}</small>
            </b-col>
            <b-col lg="2" md="12" sm="12" class="text-right">
              <b-button :to='{name: "crm.calendar", query: {id: ev.id,time: ev.starttime,view: true}}' variant="outline-primary" size="sm" block>Details</b-button>
            </b-col>
          </b-row>
        </b-list-group-item>
      </template>
      <b-list-group-item v-else class="p-3 font-italic empty-listgroup">no events</b-list-group-item>
    </b-list-group>
    <b-spinner v-else label="Loading..." small class="m-3 empty-listgroup"></b-spinner>
  </b-card>
</template>

<script>
import moment from 'moment';
import {BIconCalendar2Event,BIconCalendar2Check} from 'bootstrap-vue';
export default {
    components: {
      BIconCalendar2Event,BIconCalendar2Check
    },
    data(){
        return {
          loading: true,
          title: "Today's events",
          currentDataset: "today",
          currentDatasetTitle: "Today",
          currentDatasetTitles: [
            {id: "today", text: "Today"},
            {id: "tomorrow", text: "Tomorrow"},
            {id: "this-week", text: "This Week"},
            {id: "next-week", text: "Next Week"},
            {id: "this-month", text: "This Month"},
            {id: "next-month", text: "next Month"},
            {id: "this-year", text: "This Year"},
          ],
          events: []
        };
    },

    methods:{
      changeDataset(id){
        let item = this.currentDatasetTitles.find(o => o.id === id);
        this.currentDatasetTitle = item.text;
        this.currentDataset = item.id;

        if(id === "today"){
          this.fetchEvents(moment(), moment().endOf('day'));
        }
        else if(id === "tomorrow"){
          this.fetchEvents(moment().add(1,'day').startOf('day'),moment().add(1,'day').endOf('day'));
        }
        else if(id === "this-week"){
          this.fetchEvents(moment().startOf('isoWeek'),moment().endOf('isoWeek'));
        }
        else if(id === "next-week"){
          this.fetchEvents(moment().add(1,'week').startOf('isoWeek'),moment().add(1,'week').endOf('isoWeek'));
        }
        else if(id === "this-month"){
          this.fetchEvents(moment().startOf('month'),moment().endOf('month'));
        }
        else if(id === "next-month"){
          this.fetchEvents(moment().add(1,'month').startOf('month'),moment().add(1,'month').endOf('month'));
        }
        else if(id === "this-year"){
          this.fetchEvents(moment().startOf('year'),moment().endOf('year'));
        }
        else{
          //nothing
        }
      },

      setTitle(datestart,dateend){
        let start = datestart.format('DD.MM.YYYY');
        let end = dateend.format('DD.MM.YYYY');
        let title = [start];
        if(start !== end){
          title.push(end);
        }

        this.title = title.join(' / ');
      },

      parseEvents(data){
        return data.map(item => {
          let start = moment.unix(item.starttime);
          item.starttimeFormatted = start.format("HH:mm");
          item.endtimeFormatted = moment.unix(item.endtime).format("HH:mm");
          item.date = start.format("DD.MM.YYYY");
          item.pot = start < moment() ? 'past' : 'future';
          return item;
        })
      },

      fetchEvents(datestart,dateend){
        this.loading = true;
        let start = moment(datestart).valueOf() / 1000;
        let end = moment(dateend).valueOf() / 1000;

        this.setTitle(datestart,dateend);

        this.$api.get(`crm/calendar/events`,{
          params: {start,end,perPage: 20}
        }).then(res => {
          this.loading = false;
          let {records} = res.data;
          this.events = this.parseEvents(records);
        }).catch((e) => {console.log(e,'cannot get events')});
      }
    },

    mounted(){
      this.changeDataset('today');
    },
};
</script>
